<template>
  <div>
    <v-dialog v-bind="$attrs" v-on="$listeners" v-model="internalValue" :width="width" :fullscreen="internalFullscreen" scrollable>
      <v-card :loading="internalLoading">
        <v-toolbar elevation="0" outlined class="text-h5 grey lighten-3" dense>
          <v-toolbar-title v-if="internalLoading">
            <v-progress-circular indeterminate color="primary" :size="20" :width="2" class="mr-3"></v-progress-circular> Loading...
          </v-toolbar-title>
          <v-toolbar-title v-else>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <slot name="top-actions"></slot> -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="light" small v-bind="attrs" v-on="on" @click="fullscreenDialog()" icon v-if="!hideFullScreenButton">
                <v-icon v-if="!internalFullscreen">mdi-fullscreen</v-icon>
                <v-icon v-else>mdi-fullscreen-exit</v-icon>
              </v-btn>
            </template>
            <span v-if="!internalFullscreen">Fullscreen</span>
            <span v-else>Exit Fullscreen</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="light" class="mr-1" small v-bind="attrs" v-on="on" @click="closeDialog()" icon v-if="!hideCloseButton">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Close</span>
          </v-tooltip>
        </v-toolbar>
        <v-sheet class="d-flex flex-row flex-wrap align-center py-1 px-1" elevation="1" outlined>
          <app-button @click="closeDialog()" title="Close Dialog" mdi-icon="mdi-close"></app-button>
          <v-divider vertical class="mx-2"></v-divider>
          <app-button @click="getData(true)" title="Reload" mdi-icon="mdi-sync"></app-button>
          <app-button @click="dialog_add = true" title="Add" mdi-icon="mdi-plus"></app-button>
          <slot name="toolbar"></slot>
        </v-sheet>
        <v-card :min-height="(internalFullscreen) ? minHeightContent : null" style="overflow-y: auto;" class="rounded-0 grey lighten-2" elevation="0">
          <v-card-text>
            <app-comment-list-items :document_id="parseInt(document_id)" :source_document="source_document" :document_no_="document_no_" ref="refAppCommentListItems"></app-comment-list-items>
          </v-card-text>
        </v-card>
        <v-divider></v-divider>
        <v-card-actions>
          <slot name="actions"></slot>
          <v-spacer></v-spacer>
          <v-btn @click="closeDialog()" v-if="!hideCloseButton">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <app-comment-add :document_id="parseInt(document_id)" :source_document="source_document" :document_no_="document_no_" v-model="dialog_add" @callback="getData(true)"></app-comment-add>
  </div>
</template>

<script>
export default {
  inheritAttrs: false, // Supaya $attrs tidak diteruskan otomatis ke root element
  props: {
    value: { // Menggunakan prop 'value' karena Vue 2 menggunakan v-model dengan prop 'value'
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Dialog'
    },
    width: {
      type: String,
      default: '1400px'
    },
    hideCloseButton: {
      type: Boolean,
      default: false
    },
    hideFullScreenButton: {
      type: Boolean,
      default: false
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    document_id: {
      type: Number,
      default: 0,
    },
    source_document: {
      type: String,
      default: "",
    },
    document_no_: {
      type: String,
      default: "",
    },
    loading: null,
  },
  data() {
    return {
      internalValue: this.value, // Menyinkronkan value dengan internalValue
      internalFullscreen: this.fullscreen,
      minHeightContent: '',
      internalTitle: '',

      dialog: false,
      internalLoading: false,
      comments: [],
      limit: 5,
      dialog_add: false,
      search: "",
      datatable_options: {
        page: 1,
        itemsPerPage: 5,
      },
      itemsPerPageOption: [5, 20, 50, 100, 500],
      totalPage: 0,
      datatable: {
        loading: null,
        loadingText: "Loading... Please wait...",
        totalRows: 0,
        columns: [
          { text: "id", value: "id", sortable: false },
        ],
        data: [],
        sortBy: 'id',
        sortDesc: false,
      },

      loading_add: false,
      comment_body: "",
    };
  },
  computed: {
    comments_limit() {
      var cl = [];
      for (var i = 0; i < this.limit; i++) {
        cl.push(this.comments[i]);
      }
      return cl;
    },

    _document_id() {
      return this.document_id;
    },

    _source_document() {
      return this.source_document;
    }
  },
  watch: {
    value(val) {
      this.internalValue = val;
    },
    internalValue(val) {
      this.$emit('input', val);

      if (val == false) {
        this.$emit("callback");
      }
    },
    internalFullscreen(val) {
      this.$emit('fullscreen', val);

      if (val == true) {
        const viewportHeight = document.documentElement.clientHeight;
        this.minHeightContent = viewportHeight - (148)
      }
    },

    document_id(val) {
      if (val) {
        this.getData(true)
      }
    },
    datatable_options: {
      handler() {
        this.getData();
      },
      deep: true,
    },

    search: {
      handler() {
        this.datatable_options.page = 1;
        this.getData();
      },
      deep: true,
    },
  },
  methods: {
    closeDialog() {
      this.internalValue = false; // Menutup dialog dan mengupdate internal value
    },
    fullscreenDialog() {
      this.internalFullscreen = !this.internalFullscreen;
    },
    getData(refresh = false) {
      if(typeof this.$refs.refAppCommentListItems !== 'undefined'){
        this.$refs.refAppCommentListItems.getData(refresh);
      }
    }
  },
};
</script>

<style></style>