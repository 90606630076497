<template>
  <div>
    <v-dialog v-bind="$attrs" v-on="$listeners" v-model="internalValue" :width="width" :fullscreen="internalFullscreen" scrollable>
      <v-card :loading="loading">
        <v-toolbar elevation="0" outlined class="text-h5 grey lighten-3" dense>
          <v-toolbar-title v-if="loading">
            <v-progress-circular indeterminate color="primary" :size="20" :width="2" class="mr-3"></v-progress-circular> Loading...
          </v-toolbar-title>
          <v-toolbar-title v-else>{{ title }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <!-- <slot name="top-actions"></slot> -->
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="light" small v-bind="attrs" v-on="on" @click="fullscreenDialog()" icon v-if="!hideFullScreenButton">
                <v-icon v-if="!internalFullscreen">mdi-fullscreen</v-icon>
                <v-icon v-else>mdi-fullscreen-exit</v-icon>
              </v-btn>
            </template>
            <span v-if="!internalFullscreen">Fullscreen</span>
            <span v-else>Exit Fullscreen</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="light" class="mr-1" small v-bind="attrs" v-on="on" @click="closeDialog()" icon v-if="!hideCloseButton">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </template>
            <span>Close</span>
          </v-tooltip>
        </v-toolbar>
        <v-sheet class="d-flex flex-row flex-wrap align-center py-1 px-1" elevation="1" outlined>
          <app-button @click="closeDialog()" title="Close Dialog" mdi-icon="mdi-close"></app-button>
          <v-divider vertical class="mx-2"></v-divider>
          <slot name="toolbar"></slot>
        </v-sheet>
        <v-card :min-height="(internalFullscreen) ? minHeightContent : null" style="overflow-y: auto;" class="rounded-0 grey lighten-2" elevation="0">
          <v-card-text :class="(noPadding) ? 'ma-0 pa-0': ''">
            <slot></slot>
          </v-card-text>
        </v-card>
        <v-divider></v-divider>
        <v-card-actions>
          <slot name="actions"></slot>
          <v-spacer></v-spacer>
          <slot name="actions-right"></slot>
          <v-btn @click="closeDialog()" v-if="!hideCloseButton">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  inheritAttrs: false, // Supaya $attrs tidak diteruskan otomatis ke root element
  props: {
    value: { // Menggunakan prop 'value' karena Vue 2 menggunakan v-model dengan prop 'value'
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: 'Dialog'
    },
    width: {
      type: String,
      default: '1400px'
    },
    hideCloseButton: {
      type: Boolean,
      default: false
    },
    hideFullScreenButton: {
      type: Boolean,
      default: false
    },
    fullscreen: {
      type: Boolean,
      default: false
    },
    noPadding: {
      type: Boolean,
      default: false
    },
    callback: null,
    loading: null,
  },
  data() {
    return {
      internalValue: this.value, // Menyinkronkan value dengan internalValue
      internalFullscreen: this.fullscreen,
      minHeightContent: '',
      internalTitle: '',
    };
  },
  watch: {
    value(val) {
      this.internalValue = val;
    },
    internalValue(val) {
      this.$emit('input', val);

      if (val == false) {
        if (typeof this.callback == 'function')
          this.callback();
      }
    },
    internalFullscreen(val) {
      this.$emit('fullscreen', val);

      if (val == true) {
        const viewportHeight = document.documentElement.clientHeight;
        this.minHeightContent = viewportHeight - (148)
      }
    },
  },
  methods: {
    closeDialog() {
      this.internalValue = false; // Menutup dialog dan mengupdate internal value
    },
    fullscreenDialog() {
      this.internalFullscreen = !this.internalFullscreen;
    },
  },
};
</script>

<style></style>